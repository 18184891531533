.FormGroup {
  margin: 0 auto;
  margin-bottom: 2em;
  padding: 0;
  border-style: none;
  background-color: "whitesmoke";
  will-change: opacity, transform;
  box-shadow: 0 6px 9px rgba(50, 50, 93, 0.06), 0 2px 5px rgba(0, 0, 0, 0.08),
    inset 0 0.5px 0 yellowgreen;
  border-radius: 4px;
}

.FormRow {
 
  display: flex;
  align-items: center;
  width: inherit;
  margin: 1em;
  padding: 0.2em;
  height: 1.5em;

}

.FormRowLabel{
  display: inline-block;
  width: 20%;
  padding-right: 3em;
  font-weight: 400;
}

.StripeElement--webkit-autofill {
  background: transparent !important;
}

.StripeElement {
  width: 100%;
  padding: 11px 15px 11px 0;
  
}
.Form{
  width: 100%;
  height: 100%;  
  padding: 0.2em;
  max-height: inherit;
 
}
.stripe-wrapper{
  height: 100%;
  width: 80%;
  max-height: inherit;
  margin: 0 auto;
  margin-top: 3em;
  
}


@media screen and (max-width: 800px){
  .stripe-wrapper{
    width: inherit;
  }
 
  .FormRowLabel {
    font-size: 0.7em;
   
  }
  .FormRow {
    display: flex;
   
    width: 100%;
    margin: 0 auto;
  
  }
  .FormGroup {
    width: inherit;
    padding: 0.2em;
    
  }
}
.ErrorMessage{
  color: maroon;
  font-size: 0.8em;
  font-variant: small-caps;
 
}
label{
  font-size: 1em;
}
::-webkit-input-placeholder{
  font-size: 1em;
}
::-moz-placeholder{
  font-size: 1em;
}
:-ms-input-placeholder{
  font-size: 1em;
}
::-ms-input-placeholder{
  font-size: 1em;
}
::placeholder{
  font-size: 1em;
}
