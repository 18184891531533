@import url('https://fonts.googleapis.com/css2?family=Montserrat&family=Roboto&family=Lato&family=Poppins:wght@300&family=Phudu&family=Arimo:wght@400&display=swap');
:root {
  --appBckgColor: rgba(245, 239, 230);
  --appFamily: 'Montserrat', sans-serif;
  --appPrimaryColor: darkcyan;
  --appLightColor: rgba(4, 245, 245, 0.7);
  --appAdminColor: #1a2f42;
  --appWarning: red;
  --appH1: darkcyan;
  --appH2: #F0604D;
  --appH3: #1a2f42;
  --fontBold: 600;
  --fontLight: 400;
  --appHeight: 100vh;
}

.hero-svg{
  -o-object-fit: contain;
     object-fit: contain;
  width: 100%;
  height: auto;
  position: relative;
  display: block;
}
.hero-wrapper{
  position: relative;
  display:block;
  max-width: 100vw;
  width: 100%;
}

.App {
  text-align: center;
  width: 100%;  
  height: var(--appHeight);
  max-width: 100vw;
  max-height: 100vh;
  margin: 0 auto;
  padding: 0; 
  display: block;
  position: relative;
  overflow-y: auto;
  overflow-x: hidden;
  font-family: "Montserrat";

}
html, body{
  overflow-y: hidden;
  overflow-x: hidden;
}
.balade-logo{
  transform: scaleX(-1);
}
form input{
  border-style: none;
  border-width: 0em;
}
.select_input{
  border-style: none;  
  border-width: 0em;
  border-bottom : 0.2em solid var(--appColor);
  opacity: 0.6;
  background-color: whitesmoke;
  letter-spacing: 1pt;
  width: 4em;
  height: 3em;
  margin-right: 1em;
  font-size: 1em;
}
.select_input_day{
  border-style: none;  
  border-width: 0em;
  border-bottom : 0.2em solid var(--appColor);
  opacity: 0.6;
  background-color: whitesmoke;
  letter-spacing: 1pt;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  height: 3em;
  margin-right: 1em;
  font-size: 1em;
}
.select_input_option{
  color:#226D68;

}
select:active, select:hover{
  outline-color:#226D68;
}
select{
  outline-color: none;
}
select.select_input_day option:hover,
    select option:focus,
    select option:checked,
    select option:active {
      box-shadow: 0 0 10px 100px #1882A8 inset;
    }

   


.select_input:active{
  border-style: none;
}


.input-div{
  display: grid;
  grid-template-columns: 70% 30%;
  margin: 0 auto;
  margin-left: 10em;
  margin-right: 10em;
}
/*range input for riders*/
.rider-div{
  margin-top: 1em;
}

/* The slider itself */
.input-range-riders {
  -webkit-appearance: none;  /* Override default CSS styles */
  -moz-appearance: none;
       appearance: none;
  width: 100%; /* Full-width */
  height: 2px; /* Specified height */
  background: #282c34; /* Grey background */
  outline: none; /* Remove outline */
  opacity: 0.7; /* Set transparency (for mouse-over effects on hover) */ 
  /* 0.2 seconds transition on hover */
  transition: opacity .2s;
}

/* Mouse-over effects */
.input-range-riders:hover {
  opacity: 1; /* Fully shown on mouse-over */
  cursor: pointer; 

}

/* The slider handle (use -webkit- (Chrome, Opera, Safari, Edge) and -moz- (Firefox) to override default look) */
.input-range-riders::-webkit-slider-thumb {
  -webkit-appearance: none; /* Override default look */
  appearance: none;
  width: 5px; /* Set a specific slider handle width */
  height: 25px; /* Slider handle height */
  background: var(--appPrimaryColor); 
  cursor: pointer; /* Cursor on hover */  
  border-radius: 20%;
}
.input-range-riders::-webkit-slider-thumb:hover{
  transform: scaleX(1.2);
}

.input-range-riders::-moz-range-thumb {
  width: 5px; /* Set a specific slider handle width */
  height: 25px; /* Slider handle height */
  background: var(--appPrimaryColor); /* Green background */
  cursor: pointer; /* Cursor on hover */
}

.intro{
 
  position: relative;
  display: grid;
  /* grid-template-columns: repeat(auto-fit, minmax(255px, 1fr)); */
  grid-template-columns: repeat(auto-fit, minmax(10vh, 1fr));
  -moz-column-gap: 2rem;
       column-gap: 2rem;
  /* border-bottom: solid; */
  border-width: 1px;
  border-color: var(--appPrimaryColor);
  padding-bottom: 1rem;
  border-radius: 5px;
}

.intro_select_input_admin{
  border-style: none;  
  border-width: 0em;
  border-bottom : 0.2em solid var(--appPrimaryColor);
  opacity: 0.6;
  background-color: whitesmoke;
  letter-spacing: 1pt;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  height: 3em;
  margin: 0 auto;
  font-size: 1em;
}

.intro_select_input_abs{
  border-style: none;  
  border-width: 0em;
  border-bottom : 0.2em solid #226D68;
  opacity: 1;
  background-color: whitesmoke;
  letter-spacing: 1pt;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  height: 3em;
  margin: 0 auto;
  font-size: 1em;
}

.intro_select_input{
  border-style: none;  
  border-width: 0em;
  border-bottom : 0.2em solid var(--appPrimaryColor);
  opacity: 0.6;
  background-color: whitesmoke;
  letter-spacing: 1pt;
  width: 4em;
  height: 3em;
  margin: 0 auto;
  font-size: 1em;

}

.label-card{
  color: #282c34;
  opacity: 0.7;
 
}
.calendar-message{
  margin-top: 1em;
}
.admin-page-title{
 font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
 padding-top: 2em;
-webkit-font-smoothing: antialiased;
-moz-osx-font-smoothing: grayscale;
background-color:whitesmoke;
color:  #282c34;
font-weight: 400;
opacity: 0.8;
font-size: 1.7em;
}
.labelless{
  color: var(--appPrimaryColor);;
  font-size: 2em;
  opacity: 1;
}
.labelless-abs{
  color: #226D68;
  font-size: 2em;
  opacity: 1;
}

.btnless{
  cursor: pointer;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}


.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.page{ 
display: block;
width: 100%;
margin: 0 auto;

}
.confirm-page{
  align-items: center;
  margin: 2em;
  margin-top: 18rem;
  padding-left: 1rem;
  padding-right: 1rem;
}

.booking-page{ 
  align-items: center;
  margin: 1em;
  margin-top: 2rem;
  padding-left: 1rem;
  padding-right: 1rem;
}
.admin-booking-page{ 
  align-items: center;
  margin: 1em;
  margin-top: 1rem;
  padding-left: 1rem;
  padding-right: 1rem;
}
.event-choice{
  width: 100%;
  margin: 0 auto;
  opacity: 0.8;
  margin-top: 2em;
  border-width: 0.5px;
  border-style: none;
  border-top: solid;
  border-color: var(--appPrimaryColor);
  border-radius: 5px;
}
.gene-img-crop{
  -o-object-fit: cover;
     object-fit: cover;
  -webkit-clip-path: circle();
          clip-path: circle();
}

.event-div{
  width: 100%;
  margin: 0 auto;
  margin-top: 1rem;
  margin-bottom: 1em;
  align-items: left;
}
.booking-bckg{
  margin: 0 auto; 
  align-items: center;
 
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center bottom;
  width: inherit;
  height: 100rem;
  -o-object-fit: none;
     object-fit: none;
}
.sign-bckg{
  margin: 0 auto; 
  align-items: center;
  background-color: #282c34;  
  background-image: url('./images/rudyManege.jpg');  
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center bottom;
  width: 100%;
  height: 30rem;
  -o-object-fit: none;
     object-fit: none;
}
.radio-input{
  height: 1.1em;
  width: 1.1em;
}
.radio-input:active{
  color: #226D68;
}
.reinit-page{
  margin: 5em;
}
/*HEADER COMPONENT*/
.header-title{
 
  position: relative; 
  display: block;
  font-weight: lighter;
  font-variant: capitalize;
  font-size: 2em;
  color: white;  
}

.title{
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  position: relative; 
  display: inherit;
  font-weight: lighter;
  text-transform: capitalize;
  font-style: oblique;
  margin-top: 0.5em;
  font-size: 1em;
  padding-top: 1em;
}

.header-subtitle{
  position: relative;
  display: inherit;
  font-weight: 400;
  padding-top: 1em;
  color: yellowgreen; 
  font-size: 1.5em;
}

.subtitle{
  position: relative;
  display: inherit;
  font-weight: lighter;
  padding-top: 1em;
  color: yellowgreen;
  font-size: 0.8em;
  padding-bottom: 1em;
}
.welcome{
  position: relative;
  display: inline-flex;  
  padding-top: 1em;
  color: #282c34;
  font-size: 1.5em;
  font-weight: 600;
}
.badge{
  border: #1a2f42 2px solid;
  -webkit-clip-path: circle();
          clip-path: circle();
  border-radius: 50%;
}
.plus-wrapper{
  display: inline-flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: center;
  align-items: flex-start;
}
.plus-list{
  position: relative;
  display: -ms-inline-grid;
  text-align: left;
  padding: 1em;
  font-size: 1.5em;
  color: #226D68;
}
.plus-list span{
  font-size: 0.8em;
  color:#1a2f42;
}
.page-title{
  font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  padding-top: 1em;
 -webkit-font-smoothing: antialiased;
 -moz-osx-font-smoothing: grayscale;
 background-color:whitesmoke;
 color:  #282c34;
 font-weight: 400;
 opacity: 0.8;
 font-size: 3em;
}

.panel-title{
  color: var(--appPrimaryColor);
  display: inline-block;
  position: relative;
  align-self: center; 
  font-weight: lighter;
  font-size: 1.4em;
}
.panel-title-abs{
  color: #226D68;
  opacity: 1; 
  display: inline-block;
  position: relative;
  align-self: center; 
  font-weight: lighter;
  font-size: 1.4em;
}
.h2-abs{
  color: #226D68;
}
.header-wrapper{ 
 height: -webkit-fit-content; 
 height: -moz-fit-content; 
 height: fit-content;
 background-color: rgba(40, 46, 46, 0.8); 
  mix-blend-mode: soft-light;
 /* background-color: rgba(116, 2,145, 0.2); */
 display: flex;
 box-shadow: 0 2px 4px rgb(214, 191, 219), 0 3px 6px rgba(0, 0, 0, 0.5); 
 
}


.header-wrapper-admin{  
  mix-blend-mode: soft-light;
 background-color: rgba(114, 6,105, 0.480);
 display: flex;
 box-shadow: 0 2px 4px rgba(201, 4, 250, 0.4), 0 3px 6px rgba(0, 0, 0, 0.5); 
}

.container-conditions{
  display: block;

}
.closed-div{
  position: absolute;
  opacity: 0;
  width: 0;
  height: 0;
}
.close-button{
  position: absolute;
  top: 0;
  right: 0;
  color:white;
  background-color: rgba(171, 71, 163, 0.473);
  width: 30px;
  height: 30px;
  font-size: 1em;
  border-style: none;
}
.cgv-div{
  width: 80%;
  height: 80%;
  margin: 0 auto;
  overflow-x: hidden;
  overflow-y: scroll;
  background-color: rgba(40, 46, 46, 0.8);
  color:rgb(216, 205, 215);
  position: relative;
  z-index: 1000;
}
.cgv-div h3{
  color: #AB47A3;
  font-size: 1.5em;
  font-weight: 400;
  font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  -webkit-font-smoothing: antialiased;
}
/*SCROLLBAR*/
.cgv-div::-webkit-scrollbar {
  width: 0px; /* width of the entire scrollbar */
  
  }
.cgv-div h2{  
  text-align: center;
  position: relative;
  width: 100%;
  display: inline-block;
}
.cgv-div h2.cgv-title{
  position: relative;
  display: flex;
  width: 100%;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: stretch;
  justify-content: center;
  align-items: baseline;

}

.cgv-div span{
  color:rgb(216, 205, 215);
  text-align: justify; 
  font-size: 1em;

  
}
.cgv-div h2 span.first-letter{
  color:#AB47A3;
  text-transform:uppercase;
  padding:0;
  font-size: xx-large;
}
.cgv-div p span{
  color:rgb(216, 205, 215);
  text-align: justify; 
  font-size: 1em; 
}
.cgv-div .stripe{
  color: #AB47A3;
  
}
.cgv-div .decale{
  padding-left: 4em;
 
}
.cgv-div .no-cap{
text-transform: lowercase;
font-weight: lighter;
font-style: oblique;
}

.cgv-div h2 span{
  color:rgb(216, 205, 215);
  font-size: smaller;
  font-weight: lighter;
  font-style: oblique;
}
.cgv-div span.address-club{
    text-decoration: none;
    color: white;
    font-style: oblique;
    font-size: larger;
    
  
}
.cgv-div span.cgv-span-details{
  padding-right: 1em;
  color: #AB47A3;
}
.cgv-div p{
  padding-left: 1em;
  padding-right: 1em;
 display: block;
 padding: 0.5em;
}
.first-cgv{

 display: block;
 padding: 0.5em;
}

.cgv-details{
  text-align: left; 
}
.close-button:hover{
  cursor: pointer;
  color:rgba(114, 6,105, 0.480);
  background-color: rgb(214, 191, 219);

}
.modal-div{
  position: absolute;
  display: flex;
  top: 0;
  left: 0;
  margin: 10em;
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 100;
  width: 100%;
  height: 100%;
  overflow-x: hidden; 
  margin: 0 auto;
  z-index: 2000;
  overflow-y: hidden;
  align-items: center;
}
.header-display{
  position: absolute;
  width: 100%;  
  background-color: 0 0 0 rgba(0, 0, 0, 0.5);
 /*  opacity: 0.4;
  filter: saturate(10);  
  background-color: #282c34; */
  
}
.header-fixed{
  --headerHeight: fit-content;
  position: fixed;
  top: 0;
  width: inherit; 
  height: var(--headerHeight);
  text-align: right;
  z-index: 5;
  
 
}
.header-rel{
  --headerHeight: fit-content;
  position: relative;
  top: 0;
  width: inherit; 
  height: var(--headerHeight);
  text-align: right;
  z-index: 5;
  
 
}


.header {
  width: 100%;  
  height: 5em;
  max-width: inherit;
  margin: 0;
  padding-right: 0em;
  position: relative;
  display: grid;
  /* grid-template-columns: repeat(auto-fit, minmax(255px, 1fr)); */
  grid-template-columns: repeat(auto-fit, minmax(10vh, 1fr));
  /* grid-template-columns: 36% 23% 39% ; */
  grid-row-gap: 1%;
  grid-column-gap: 1%;
  color: white;
  /*padding-bottom: 0.5em; */
 
  text-align: right;
  vertical-align: bottom; 
  
}


.left-container{
  display: flex;
  font-size: calc(10px + 2vmin);
  /* max-block-size: inherit; */
 /*  block-size: inherit; */
  align-items: center;
  padding-top: 2px;
  padding-left: 2px;
  margin: 0 auto;
  position: relative;
  /* max-width: fit-content; */
  width: inherit;
  height: inherit;
}
.center-container{
 max-block-size: 100%;
 vertical-align: center;
 text-align: center;
 margin: 0 auto;
 width: 100%;

}

.center-container-rdv{
 max-block-size: 100%;
 vertical-align: center;
 text-align: center;
 margin: 0 auto;
 width: 100%;
 height: inherit;

}
.right-container {
 position: relative;
 max-block-size: 100%; 
 width: 100%;
 height: inherit;
 margin-bottom: 0;
}

.medail-vignes{
  max-block-size: 100%;  
  position: absolute;
  -o-object-fit: contain;
     object-fit: contain;
  left: -1em;
  top:0;
  align-items: left;
  offset-block-start: 30px;
  -webkit-clip-path: inset(0% 0% 0% 25%);
          clip-path: inset(0% 0% 0% 25%); 
  text-align: left; 
  
}
.nav-btn {
  background: transparent;
  color: var(--appPrimaryColor);
  opacity: 0.8;
  border: none;
  cursor: pointer;
  outline: none;
  width: 3em;
  height: 3em;
  margin-right: 0.5em;
  z-index: 100000;
 
}
.nav-btn:hover{
  cursor:pointer;
}
.nav-icon {
  font-size: 1.5em;
  color: darkcyan;
  opacity: 1;
}
.nav-icon-presta{
  font-size: 2.5em;
  color: var(--appPrimaryColor);
  text-align: center;

}
.nav-links {
  height: auto;
  display: flex; 
  text-decoration: none;
}
.nav-links a {
  margin: 0 1rem;
  padding: 0.5rem 0;
}
.nav-links a {
  display: block;
  text-decoration: none;
  padding: 2em;
  color: var(--mainBlack); 
  text-align: right;
 
}
.rc-anchor-normal .rc-anchor-content {
  
  }

  /*IPHONE 4*/
  @media only screen and (-webkit-min-device-pixel-ratio : 1.5), only screen and (min-device-pixel-ratio : 1.5){
    .plus-wrapper{
  
      display: inline-flex;
      flex-direction: column;
      flex-wrap: nowrap;
      align-content: center;
      justify-content: center;
      align-items: flex-start;
  }
    .input-div{
      margin-right: 1em;
      margin-left: 1em;
    }
    
    .headerimg{
      width: auto;
    }
    
    .logo-admin{
      width: auto;
      transform: scale(1.2);
      margin-left: 1.5em;
    }
    .logo{
      width: auto;
      transform: scale(1.2);
      margin-left: 1.5em;
    }
    

    
    .vignes-wrapper{
      opacity: 0;
      height: inherit;
      
    }
    .header-wrapper{
      display: block;
      height: -webkit-fit-content;
      height: -moz-fit-content;
      height: fit-content;
    }
    .header-fixed{
      max-height: 5em;
    }
    .left-container{
      margin-bottom: 0.5em;
    }
    .headerimg{
      width: 60%;
    }
    .confirm-page{
      margin: 0 auto;
      width: 80%;
      margin-top: 10rem;
      align-items: center;
   
    }
    
    .center-container-rdv{
      height: inherit;
    }
    .medail-vignes{
      left: -0.5em;
      opacity: 0;
    }
    
    h1.page-title{
      font-size: 2em;
      /* padding-top: 2em;    */   
    }
    .form-reinit{
      padding-top: 5em;
    }
    }
/*IPHONE 3G*/
    @media screen and (device-width: 320px) and (device-height: 480px) and (-webkit-device-pixel-ratio: 1){
      .plus-wrapper{
  
        display: inline-flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: center;
        align-items: flex-start;
    } 
     
      .cgv-div p{
      padding-left: 0;
      padding-right: 0;
      display: block;
    }
    
     
      .input-div{
        margin-right: 1em;
        margin-left: 1em;
      }
      
      .headerimg{
        width: auto;
      }
     
      .logo-admin{
        width: auto;
        transform: scale(1.2);
    margin-left: 1.5em;
      }
      .logo{
        width: auto;
        transform: scale(1.2);
        margin-left: 1.5em;
      }
      
    
    
    .vignes-wrapper{
      opacity: 0;
    }
    .header-wrapper{
      display: block;
      height: -webkit-fit-content;
      height: -moz-fit-content;
      height: fit-content;
    }
    .header-fixed{
      max-height: 120px;
    }
    .left-container{
      margin-bottom: 0.5em;
    }
    
    .confirm-page{
      margin: 0 auto;
      width: 80%;
     /*  margin-top: 10rem; */
      align-items: center;
   
    }
    
    .center-container-rdv{
      height: inherit;
    }
    .medail-vignes{
      left: -0.5em;
      opacity: 0;
    }
    
    h1.page-title{
      font-size: 2em;
      padding-top: 3em;      
    }
    .form-reinit{
      padding-top: 5em;
    }
    }
/*IPHONE 5*/
@media screen and (device-width: 320px) and (device-height: 568px) and (-webkit-device-pixel-ratio: 2){
  .plus-wrapper{
  
    display: inline-flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: flex-start;
}
  
  .input-div{
    margin-right: 1em;
    margin-left: 1em;
  }
 
  .headerimg{
    width: auto;
  }
  
  .logo-admin{
    width: auto;
    transform: scale(1.2);
    margin-left: 1.5em;
  }
  .logo{
    width: auto;
    transform: scale(1.2);
    margin-left: 1.5em;
  }
  
  
 
  .vignes-wrapper{
    opacity: 0;
  }
  
  .header-wrapper{
    display: block;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
  }
  .header-fixed{
    max-height: 120px;
  }
  .left-container{
    margin-bottom: 0.5em;
  }
  
  .confirm-page{
    margin: 0 auto;
    width: 80%;
    margin-top: 10rem;
    align-items: center;
 
  }
  
  .center-container-rdv{
    height: inherit;
  }
  .medail-vignes{
    left: -0.5em;
    opacity: 0;
  }
  
  h1.page-title{
    font-size: 2em;
    /* padding-top: 2em;   */    
  }
  .form-reinit{
    padding-top: 5em;
  }
  }
/*ipad mini*/
  @media screen and (device-width: 768px) and (device-height: 1024px) and (-webkit-device-pixel-ratio: 1){
    .plus-wrapper{
  
      display: inline-flex;
      flex-direction: column;
      flex-wrap: nowrap;
      align-content: center;
      justify-content: center;
      align-items: flex-start;
  }
    .input-div{
      margin-right: 1em;
      margin-left: 1em;
    }
    
    .headerimg{
      width: auto;
    }
   
    .logo-admin{
      width: auto;
      transform: scale(1.2);
      margin-left: 1.5em;
    }
    .logo{
      width: auto;
      transform: scale(1.2);
      margin-left: 1.5em;
    }
   
    .radio-label{
      font-size: 0.8em;
    }
    
 
    .center-container-rdv{
      height: inherit;
    }
  
    .radio-elt{    
      font-size: 0.8em;
    }
  
    h1.page-title{
      font-size: 2em;
     /*  padding-top: 3em;    */   
    }

    
    .confirm-page{
      margin: 0 auto;
      width: 80%;
      margin-top: 10rem;
      align-items: center;
   
    }
    .home{
      padding: 0em;
    }
    .header-title{
      font-size: 1em;
    }
    .header-subtitle{
      font-size: 0.8em;
      padding-top: 0.2em;
    }
  
    .medail-vignes{
      left: -0.5em;
      opacity: 0;
    }
   
  
    .reinit-page{
      margin: 0 auto;
    }
    .table-header{
      font-size: smaller;
      font-weight: lighter;
      
    }
    .table-title{
      
      font-weight: 400;
      text-align: center;
    }
   
    .booking-page{
      margin-top: 2rem;
    }
    
  
    .panel-title{
      font-size: 1em;
    }
    .page-title{
      font-size: 1.5em;
      text-align: center;
    }
    .acc_img{
      width: 2.5em;
      height: 2.5em;
      
    }
    .paragraph{
      font-weight: 350;
      font-size: 0.8em;
      display: block;
      position: relative;
    }
   
    .bookingcolumn{
      font-size: 0.5em;
    }
    .form-reinit{
      padding-top: 5em;
    }
    }

  /*IPHONE 8*/

  @media only screen 
    and (device-width : 375px) 
    and (device-height : 667px) 
    and (-webkit-device-pixel-ratio : 2) { 
      .plus-wrapper{
  
        display: inline-flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: center;
        align-items: flex-start;
    }
      .input-div{
        margin-right: 1em;
        margin-left: 1em;
      }
      
      .headerimg{
        width: auto;
      }

      .logo-admin{
        width: auto;
        transform: scale(1.2);
        margin-left: 1.5em;
      }
      .logo{
        width: auto;
        transform: scale(1.2);
        margin-left: 1.5em;
      }
     
      .header-wrapper{
        display: block;
        height: -webkit-fit-content;
        height: -moz-fit-content;
        height: fit-content;
      }
      .vignes-wrapper{
        opacity: 0;
      }
      .header-fixed{
        max-height: 120px;
      }
      .left-container{
        margin-bottom: 0.5em;
      }
      
      .confirm-page{
        margin: 0 auto;
        width: 80%;
        margin-top: 10rem;
        align-items: center;
     
      }
    
      .center-container-rdv{
        height: inherit;
      }
      .medail-vignes{
        left: -0.5em;
        opacity: 0;
      }
      
      h1.page-title{
        font-size: 2em;
        /* padding-top: 2em;    */   
      }
      .form-reinit{
        padding-top: 5em;
      }

    }

    @media only screen 
    and (device-width : 414px) 
    and (device-height : 736px) 
    and (-webkit-device-pixel-ratio : 3) {
      .plus-wrapper{
  
        display: inline-flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: center;
        align-items: flex-start;
    }
      .input-div{
        margin-right: 1em;
        margin-left: 1em;
      }
      .headerimg{
        width: auto;
      }
      .logo-admin{
        width: auto;
        transform: scale(1.2);
        margin-left: 1.5em;
      }
      .logo{
        width: auto;
        transform: scale(1.2);
        margin-left: 1.5em;
      }
    
      .header-wrapper{
        display: block;
        height: -webkit-fit-content;
        height: -moz-fit-content;
        height: fit-content;
      }
      .vignes-wrapper{
        opacity: 0;
      }
      .left-container{
        margin-bottom: 0.5em;
      }
      
      .confirm-page{
        margin: 0 auto;
        width: 80%;
       /*  margin-top: 10rem; */
        align-items: center;
     
      }
 
      .center-container-rdv{
        height: inherit;
      }
      .medail-vignes{
        left: -0.5em;
        opacity: 0;
      }
      /* .header-fixed{
        position: absolute;
        top:0;
        width: inherit; 
        height: 150px;
        z-index: 5000;        
      } */
      h1.page-title{
        font-size: 2em;
        /* padding-top: 2em;  */     
      }
      .form-reinit{
        padding-top: 5em;
      }
     }
/* IPHONE*/
@media only screen and (min-device-width : 375px) and (max-device-width : 667px) { 
  .plus-wrapper{
  
    display: inline-flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: flex-start;
}
  .input-div{
    margin-right: 1em;
    margin-left: 1em;
  }


  .headerimg{
    width: auto;
  }
  .logo-admin{
    width: auto;
    transform: scale(1.2);
    margin-left: 1.5em;
  }
  .logo{
    width: auto;
    transform: scale(1.2);
    margin-left: 1.5em;
  }
  
  .vignes-wrapper{
    opacity: 0;
  }
  .radio-elt{    
    font-size: 1em;
  }
  
  
  .confirm-page{
    margin: 0 auto;
    width: 80%;
    margin-top: 10rem;
    align-items: center;
 
  }

  .center-container-rdv{
    height: inherit;
  }
  .medail-vignes{
    left: -0.5em;
    opacity: 0;
  }
  .header-fixed{
    max-height: 5em;
   
  }
  h1.page-title{
    font-size: 2em;
    /* padding-top: 2em;    */   
  }
  .form-reinit{
    padding-top: 5em;
  }
  
}
/*SMARTPHONES*/
@media only screen and (min-device-width : 320px) and (max-device-width : 480px) {
  .plus-wrapper{
  
    display: inline-flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: flex-start;
}
  
  .input-div{
    margin-right: 1em;
    margin-left: 1em;
  }
  
  .headerimg{
    width: auto;
  }
  .logo-admin{
    width: auto;
    transform: scale(1.2);
    margin-left: 1.5em;
  }
  .logo{
    width: auto;
    transform: scale(1.2);
    margin-left: 1.5em;
  }
  
  
  .radio-label{
    font-size: 0.8em;
  }
 
  .center-container-rdv{
    height: inherit;
  }

  .radio-elt{    
    font-size: 0.8em;
  }

  h1.page-title{
    font-size: 2em;
    /* padding-top: 2em;  */     
  }
  
  .confirm-page{
    margin: 0 auto;
    width: 80%;
    margin-top: 10rem;
    align-items: center;
 
  }
  .home{
    padding: 0em;
  }
  .header-title{
    font-size: 1em;
  }
  .header-subtitle{
    font-size: 0.8em;
    padding-top: 0.2em;
  }

  .medail-vignes{
    left: -0.5em;
    opacity: 0;
  }
 

  .reinit-page{
    margin: 0 auto;
  }
  .table-header{
    font-size: smaller;
    font-weight: lighter;
    
  }
  .table-title{
    
    font-weight: 400;
    text-align: center;
  }
 
  .booking-page{
    margin-top: 2rem;
  }
  

  .panel-title{
    font-size: 1em;
  }
  .page-title{
    font-size: 1.5em;
    text-align: center;
  }
  .acc_img{
    width: 2.5em;
    height: 2.5em;
    
  }
  .paragraph{
    font-weight: 350;
    font-size: 0.8em;
  }
  
  .bookingcolumn{
    font-size: 0.5em;
  }
  .form-reinit{
    padding-top: 5em;
  }
  }

@media screen and (max-width: 800px){
  .plus-wrapper{
  
    display: inline-flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: flex-start;
}
  .input-div{
    margin-right: 1em;
    margin-left: 1em;
  }
  
  .headerimg{
    width: auto;
  }
  .logo-admin{
    width: auto;
    transform: scale(1.2);
    margin-left: 1.5em;
  }
  .logo{
    width: auto;
    transform: scale(1.2);
    margin-left: 1.5em;
  }
 
  .radio-label{
    font-size: 0.8em;
  }
  
  .center-container-rdv{
    height: inherit;
  }

  .radio-elt{    
    font-size: 0.8em;
  }

  
  .page-title{
    font-size: 1.5em;
    text-align: center;
  }
  
  .confirm-page{
    margin: 0 auto;
    width: 80%;
    margin-top: 10rem;
    align-items: center;
 
  }
  .home{
    padding: 0em;
  }
  .header-title{
    font-size: 1em;
  }
  .header-subtitle{
    font-size: 0.8em;
    padding-top: 0.2em;
  }

  .medail-vignes{
    left: -0.5em;
    opacity: 0;
  }
 

  .reinit-page{
    margin: 0 auto;
  }
  .table-header{
    font-size: smaller;
    font-weight: lighter;
    
  }
  .table-title{
    
    font-weight: 400;
    text-align: center;
  }
 
  .booking-page{
    margin-top: 2rem;
  }
  

  .panel-title{
    font-size: 1em;
  }
  
  .acc_img{
    width: 2.5em;
    height: 2.5em;
    
  }
  .paragraph{
    font-weight: 350;
    font-size: 0.8em;
  }
  
  .bookingcolumn{
    font-size: 0.5em;
  }
  h1.page-title{
    font-size: 2em;
    /* padding-top: 2em;   */    
  }
  .form-reinit{
    padding-top: 5em;
  }

}
@media only handheld {
  .plus-wrapper{
  
    display: inline-flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: flex-start;
}
  .input-div{
    margin-right: 1em;
    margin-left: 1em;
  }
  
  .headerimg{
    width: auto;
  }
  .logo-admin{
    width: auto;
    transform: scale(1.2);
    margin-left: 1.5em;
  }
  .logo{
    width: auto;
    transform: scale(1.2);
    margin-left: 1.5em;
  }
  
  .header{
    display: none;  
    
}
.form-reinit{
  padding-top: 5em;
}

}
/* .vignes-wrapper{
  position: relative;
  display: flex; 
  width: 100%;
  height: 100%;
  margin-bottom: 0.5em;
} */
.vignes-wrapper{
  position: relative;
  display: inline-block; 
  width: 100%;
  height: inherit;
  margin-bottom: 0.5em;
  text-align: left;
  
  
}

.logo{
  position: relative;
  display: inline-block; 
  width: 100%;
  height: inherit;
  margin-bottom: 0.5em;
  text-align: right;
  -o-object-fit: contain;
     object-fit: contain;
}

.logo-admin{
  /* display: flex;
  position: relative;
  object-fit: contain;
  height: inherit; 
  align-self: self-start;
  padding-left: 20px; 
  width: inherit; */
  position: relative;
  display: inline-block; 
  width: 100%;
  height: inherit;
  margin-bottom: 0.5em;
  text-align: right;
  -o-object-fit: contain;
     object-fit: contain;
  
 
 
 
}

html{
  display: block;
  position: relative;  
  margin: 0em;
  padding: 0em;
  

}


/*Home page*/
.home{
  text-align: center;
  font-size: 1em;
  margin: 0;
  max-width:100%;
 
 
}
/*partie gauche connexion*/
.connexion-wrapper{
  position: absolute;
  height: 30em;
  width: 20em;
 
}


/*Forms*/

.link-alone{
  font-style: oblique;
  font-size: 0.8em;
  color: yellowgreen;
  text-decoration: none;
}
.link-alone:hover{ 
    color: greenyellow;  
}
.link-forgetpwd{
  font-style: oblique;
  font-size: 1em;
  color: var(--appPrimaryColor);
  text-decoration: none;
  font-weight: 400;  
  text-align: center; 
  padding-left: 2em;
  cursor: pointer;
  
 
}
  
.link-forgetpwd:hover{ 
  color: yellowgreen;  
  cursor: pointer;
}
.link-forget-wrapper{
  position: relative;
  display: block;
  text-align: left;  
  padding-top: 4em;
}

label{
    color: var(--appAdminColor);
    font-weight: lighter;
    font-size: 1em;
    padding: 0.2em;
    font-variant: small-caps;
}
.form-title{
 
 -webkit-font-smoothing: antialiased;
 -moz-osx-font-smoothing: grayscale;
 color:  #282c34;
 font-weight: 400;
 opacity: 0.8;
  text-align: center;
  display: block;
  padding-left: 0.5em; 
  padding-bottom: 0.5em;
  
}
.form-reinit{
 
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color:  #282c34;
    font-weight: 400;
    opacity: 0.8;
    text-align: center;
    display: block;
    padding-left: 0.5em; 
    padding-bottom: 0.5em;
    
    
   
 }

.form-label{
 
  /* color:  #282c34; */
  color: var(--appPrimaryColor);
  font-weight: 500;
   text-align: center;
   display: block;
   padding-left: 0.5em; 
   padding-bottom: 0.5em;
   font-size: 1.2em;
   
 }
 .form-h1{
   
    color:  var(--appH1);
    font-weight: 500;
    opacity: 0.8;
    text-align: center;
    display: block;
    padding-left: 0.5em; 
    padding-bottom: 0.5em;
    font-size: 1.8em;
 }

.space-recup{
  height: 1em;
}
.space-recup-signup{
  height: 1em;
}
.table-header{
  font-weight:500 ;
  color: #282c34;
  font-variant: small-caps;
}

.choice{
  
  font-size: 1.2em; 
  color: turquoise;
  margin: 0 auto;
  width: 100%;
  text-align: center;
  padding-bottom: 1em;
  text-transform: capitalize;
  display: block;
  font-weight: bold;
 
}

input.check{
  width: 0.5em;
  height: 0.5em;
  display: inline-block;
  position: relative;
 
 
}
div.radio{
 position: relative;
 display: grid;
 grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
 grid-column-gap: 2px;
 
 text-align: center;
 width: 100%;
 margin: 0 auto;
 margin-bottom: 1em;
}
.radio-elt{
  width: inherit;
  font-size: 1em;
}
.radio-hidden{
  display: none;
}

.or{
  text-align: center;
  font-size: 1,5em;
  font-weight: normal;
  color: var(--appPrimaryColor);
  font-variant: small-caps;
  line-height: 5em; 
}
p{
  font-size: 1.1em;
  text-align:inherit;
  color:  #282c34;
  font-weight: 400;
 
}
h1{ 
  color: darkcyan;
  opacity: 0.8;
}
.paragraph{
  padding: 0.5em;
  font-size: 1em;
  text-align:inherit;
  margin: 0 auto;
  width: inherit;
  position: relative;
}

.circle{
  border-style: double;
  border-color: yellowgreen;
  border-radius: 50%;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  height: -webkit-max-content;
  height: -moz-max-content;
  height: max-content;
  padding: 1em;
  
}

.pricing{  
  
  border-top-style: none;
  border-width: 0.5px;
  padding-top: 1em;
  margin-top: 2em;
}
.pricing-p{
  margin-top: 1.5em;
  margin-bottom: 1.5em;
}
.confirm-mess{  
  border-top: turquoise; 
  border-top-style: solid;
  border-width: 0.5px;
  padding-top: 1em;
  margin-top: 3em;
  color: #282c34;
  text-transform: none;
  font-size: 1.5em;
  font-weight: normal;
}

.admin-link{
  display: block; 
  text-decoration: underline;
  font-size: 0.8em;
  color: var(--appAdminColor);
  opacity: 0.8;
 
}
.admin-link a{
  color: var(--appAdminColor);
  font-size: 0.7em;
}
.noadmin-link{
  display: none;
}
.beta-message{
  position: relative;
  display: block;
  /* left: 20em;
  top: 18em; */
  
}
.border-beta{
  margin-top: 3em;
  box-shadow: 0 0px 4px rgba(201, 4, 250, 0.4), 0 0px 6px rgba(0, 0, 0, 0.5); 
  padding: 2em;
}
span.pricing-title{
  text-transform: uppercase;
  color: turquoise;
  font-weight: 600;  

}

h1.pricing-title{
  
  font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  padding-top: 1em;
 -webkit-font-smoothing: antialiased;
 -moz-osx-font-smoothing: grayscale; 
 color:  #282c34;

}
h2.pricing-title{
  
  font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  padding-top: 1em;
 -webkit-font-smoothing: antialiased;
 -moz-osx-font-smoothing: grayscale; 
 color:  #282c34;
}

h1.page-title{
  font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  padding-top: 1em;
 -webkit-font-smoothing: antialiased;
 -moz-osx-font-smoothing: grayscale; 
 color: darkcyan;
 /* padding-top: 2em; */

}

h2.page-title{
  font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  padding-top: 1em;
 -webkit-font-smoothing: antialiased;
 -moz-osx-font-smoothing: grayscale; 
 color:  #282c34; 
 font-size: 1.4em;
}
span.pricing-span{
  color:  #282c34; 
  /* text-transform: capitalize; */
  font-weight: 600;
}
.pricing-body{
  text-transform: uppercase;
  color: var(--appPrimaryColor);
  opacity: 0.8;
  font-weight: bold;
  font-size: 1em;
  
}
h2.pricing{
  color: #282c34;
}
a{
  text-decoration: none;
  color: darkcyan;
}
.user-logo{
  width: 5em;
  height: 5em;
  border-radius: 50%;
  padding: 0.5em;
  margin: 0 auto;
  margin-top: 2em;
  display: block;
  position: relative;
  border: 2px solid var(--appPrimaryColor);
  color: var(--appPrimaryColor);
}
/*To place styled button for "inscription" */
.button-wrapper{
  position: relative;
  display: flex;
  max-width: -webkit-fit-content;
  max-width: -moz-fit-content;
  max-width: fit-content;
  max-height: -webkit-fit-content;
  max-height: -moz-fit-content;
  max-height: fit-content;    
}
.button-wrapper-front{
  position: absolute;
  display: block;
  max-width: inherit;
  align-content: right;
 
}

.button-wrapper-stripe{
  position: relative;
  display: inline-block;
  max-width: inherit;
  align-content: right;
  left: 3em;
  margin-bottom: 2em;
  margin-left: 1em;
  width: inherit;
 
}
  .relative-wrapper{
    margin: 0 auto;  
    margin-top: 1em;
    border-style: none;
    border-top: turquoise; 
    border-top-style: solid;
    border-width: 0.5px;
 
  }

 fieldset.relative-wrapper-reinitpwd{ 
   border-style: none;

 }
 
 .form-wrapper{
  --formHeight: fit-content;
    height: var(--formHeight);
    position: relative;
    padding: 3em;
    transform: translateY(10%);
    margin: 0 auto;
    display: -webkit-flexbox;
    display: flex;
    -webkit-flex-align: center;
    align-items: center;
    justify-content: center;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    align-content: center;
    flex-wrap: wrap;
    flex-direction: column;
    margin-bottom: 10em;
  
 }


/*animating poney stroke*/
@-webkit-keyframes draw_in {
  0% {
    stroke-dashoffset: 100%;
  }
  25% {
    stroke-dashoffset: 100%;
  }
  50%{
    stroke-dashoffset: 100%;
  }
  100%{
    stroke-dashoffset: 0%;
  }
 
}
@keyframes draw_in {
  0% {
    stroke-dashoffset: 100%;
  }
  25% {
    stroke-dashoffset: 100%;
  }
  50%{
    stroke-dashoffset: 100%;
  }
  100%{
    stroke-dashoffset: 0%;
  }
 
}
/*svg poney_account*/
.my-poney {
  -webkit-animation: draw_in 3s;
          animation: draw_in 3s; 
  stroke-dasharray: 100%;
  width: 100px;   
}
.poney-account {
  position: absolute;
  display: block;
  top: -5em;
  margin: 0 auto;
  align-self: center;
  z-index: 1000;
  width: 100%;  
}
.svg-deco-01 {
  position: absolute;
  display: block;
  top: -5em;
  margin: 0 auto;
  align-self: center;
  z-index: 1000;
  width: 100%;  
}

.poney-menu{
  position: relative;
  display: inline-block;
  list-style-type: none;
 
}

.menu-span{ 
  padding-right: 1em;
  vertical-align: center;
  color: grey;
  font-weight: 600;
  font-size: 1em;
  padding-bottom: 0.5em;
  z-index: 5000;
}

.menu-span:hover{
 
  opacity: 0.5;
  color:var(--appPrimaryColor);  
  -webkit-filter:brightness(1.2);  
          filter:brightness(1.2);

  
}


.menu-li{
  align-content: right;  
  padding-right: 1em;
  padding-top: 1em;
  height: 2em;
  background-color: whitesmoke;
  text-decoration: none;

   
 
}
.rendezvous{
  display: flex;
  position: relative;
  width: auto;
  margin: 0 auto;
  opacity: 0.8;
  -webkit-filter: saturate(1);
          filter: saturate(1);
  z-index: 10000;
  height: inherit;
}


.rendezvous #nos {
  stroke-width: 2;  
  stroke-dasharray: 1000;
  stroke-dashoffset: 1000;
  -webkit-animation: dash 2.5s linear forwards;
          animation: dash 2.5s linear forwards;
  -webkit-animation-delay: 1s;
          animation-delay: 1s;
}
.rendezvous #ren {
  stroke-width: 2;
  
  stroke-dasharray: 1000;
  stroke-dashoffset: 1000;
  -webkit-animation: dash 3.5s linear forwards;
          animation: dash 3.5s linear forwards;
  -webkit-animation-delay: 2s;
          animation-delay: 2s;
}
.rendezvous #d {
  stroke-width: 2;
  
  stroke-dasharray: 1000;
  stroke-dashoffset: 1000;
  -webkit-animation: dash 3s linear forwards;
          animation: dash 3s linear forwards;
  -webkit-animation-delay: 2.75s;
          animation-delay: 2.75s;
}
.rendezvous #ez {
  stroke-width: 2;
 
  stroke-dasharray: 1000;
  stroke-dashoffset: 1000;
  -webkit-animation: dash 3.5s linear forwards;
          animation: dash 3.5s linear forwards;
  -webkit-animation-delay: 2.9s;
          animation-delay: 2.9s;
}
.rendezvous #tiret {
  stroke-width: 2;
  
  stroke-dasharray: 1000;
  stroke-dashoffset: 1000;
  -webkit-animation: dash 1s linear forwards;
          animation: dash 1s linear forwards;
  -webkit-animation-delay: 4s;
          animation-delay: 4s;
}
.rendezvous #vous {
  stroke-width: 2;
 
  stroke-dasharray: 1000;
  stroke-dashoffset: 1000;
  -webkit-animation: dash 4s linear forwards;
          animation: dash 4s linear forwards;
  -webkit-animation-delay: 4s;
          animation-delay: 4s;
}
.rendezvous #underline {
  stroke-width: 2;
  
  stroke-dasharray: 1000;
  stroke-dashoffset: 1000;
  -webkit-animation: dash 0.5s linear forwards;
          animation: dash 0.5s linear forwards;
  -webkit-animation-delay: 5.5s;
          animation-delay: 5.5s;
}

@-webkit-keyframes dash {
  to {
    stroke-dashoffset: 0;
  }
}

@keyframes dash {
  to {
    stroke-dashoffset: 0;
  }
}


.acc_img{
 width: 120px;
 height: 120px;
}


.loading{
  height: 1.5em;
  width: 1.5em;
  text-align: center;
  

  
}
.div-center{
  margin: 0 auto;
  display: inline-block;
  width: inherit;
  line-height: 2em;
}
button{
  border-style: none;
}
button:hover{
  border-style: none;
}
.submit-button-disabled{
  background-color: whitesmoke;
}
.submit-button-disabled:hover{
  background-color: whitesmoke;
}
.oklabel{
  color: #1882A8;
}
.errorlabel{
  color: gray;
}
.poney-account-wrapper{
  position: relative;
  display: flexbox;  

}
.panel-plus{
  display: inline-block;
  margin: 0 auto;
  align-self: center;
  color: var(--appPrimaryColor);
  opacity: 0.75;
  font-size: 2em; 
}
.panel-plus-abs{
  display: inline-block;
  margin: 0 auto;
  align-self: center;
  color: #226D68;
  opacity: 1;
  font-size: 2em;
 
}

.panel-left-container {
  position: relative;  
  display: inline-block;
  width: auto;
  height: inherit;
  text-align: left;
  background-repeat: repeat;
  background-size: 90%; 
  margin: 0 auto;
 }

 /*BOOKINGS*/
 .linewrapper{
  width: inherit;
  height: 100%;
  max-height: -webkit-fit-content;
  max-height: -moz-fit-content;
  max-height: fit-content;
  max-width: inherit; 
  position: relative;
  display: grid;  
  grid-template-columns: 5% 10% 15% 15% 30% 15%; 
  grid-row-gap: 1%;
  grid-column-gap: 1%;
  padding-left: 0.5em; 
  text-align: center;
  vertical-align: center;
  
 }

 .cardlinewrapper{
  
  height: inherit;
  max-height: -webkit-fit-content;
  max-height: -moz-fit-content;
  max-height: fit-content;
  width: inherit; 
  min-width: -webkit-fit-content; 
  min-width: -moz-fit-content; 
  min-width: fit-content;
  position: relative;
  display: grid;  
  grid-template-columns: 40% 45% ; 
  grid-row-gap: 5%;
  grid-column-gap: 2%;
  padding-left: 0.5em; 
  padding-top: 0.5em;
  text-align: center;
  align-items: baseline;  
 }

 .cardlinefull{
  
  height: inherit;
  max-height: -webkit-fit-content;
  max-height: -moz-fit-content;
  max-height: fit-content;
  width: inherit; 
  position: relative;
  display: inline-flexbox; 
  padding-left: 0.5em; 
  padding-top: 0.5em;
  padding-bottom: 1em;
  text-align: justify;
  align-items: baseline;  
 }

 .cardlineadmin{
  
  height: inherit;
  max-height: -webkit-fit-content;
  max-height: -moz-fit-content;
  max-height: fit-content;
  max-width: inherit;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content; 
  position: relative;
  display: block; 
  padding-left: 0.5em; 
  padding-top: 0.5em;
  text-align: justify;
  align-items: baseline;  
 }
 .cardline-center{
  color: #BF55B9;
  font-weight: 400;
  border-top: 0.1em solid #BF55B9;
  margin: 0 auto;
  height: inherit;
  max-height: -webkit-fit-content;
  max-height: -moz-fit-content;
  max-height: fit-content;
  max-width: inherit;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content; 
  position: relative;
  display: block;  
  text-align: center;
  align-items: baseline;  
 }

 .cardline{

 }

 .bookingcolumn{
   display: inline-block;
   width: inherit;
   min-width: -webkit-fit-content;
   min-width: -moz-fit-content;
   min-width: fit-content;
   font-size: 0.8em;
 }
 .bookingcolumn-title{
    font-weight: 600;
    color: var(--appPrimaryColor);
    opacity: 0.8;
    text-align: center;

 }
 .bookingcolumnadmin{
  display: inline-block;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;  
  font-size: 1em;
  
}
.admincolumn-card{
  font-weight: 600;
    color: #282c34;
    opacity: 1;
    text-align: center;
}

.bookingcolumnstage{
  display: inline-block;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;  
  font-size: 1em;
  margin: 1em ;  
}

 .bookingline{
  display: inline-block;
  width: inherit;
  font-size: 1em;
  
}
 .h-column{
   color: #282c34;
   display: inline-block;
   width: inherit;
 }
 .datetime-wrapper{
   display: inline-flexbox;
 }
 

