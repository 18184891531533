@import url('https://fonts.googleapis.com/css2?family=Montserrat&family=Passions+Conflict&family=Lato&family=Poppins:wght@300&&family=Phudu&display=swap');
:root{
    --appBoxShadow: 0 2px 4px rgba(86, 84, 148, 0.4), 0 3px 6px rgba(0, 0, 0, 0.5); 
  --appMainColor: #e7c861;
  --appLightColor: rgba(192, 193, 218, 1);
  --appTransColor: rgba(0, 0, 0, 0.2);
  --appDarkGold: #eabd3c;
  --appGold: #e7c861;
  --appExtraLightGold: #f0eac5;
  --appNavGold: #faf3c8eb;
  --appLightGold: #ebd79a;
  --appWhiteColor: whitesmoke;
  --appGreenColor: #7FA58E;
  --appDarkGreenColor: rgba(11, 116, 77, 0.2);
  --appWhiteTransColor: rgba(255, 255, 255, 0.8);
  --appLightGreenColor: #d0e5c97a;
  --appLightGreenColorPlain: #e1eedd;
  --appDarkBlue: rgb(22, 22, 106);
  --appBlue: #565494;
  --appLightBlue: #c0c1da;
  --calendarMainColor: darkcyan;
  --calendarLightColor: rgb(16, 175, 175);
  --calendarDarkColor: rgba(5, 92, 92, 0.887);
}

  /*input*/
  .input-deco-wrapper{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    margin: 1em;
  }

  .calendar-deco{
    font-size: 2em;
    color: var(--appDarkGold);
    margin-right: 1em;
    margin-left: 1em;
  }
  .input-date{
    line-height: 2em;
    border-radius: 20px;
    border: none;
    border-bottom: 1px solid #7FA58E;
    text-align: center;
    outline: none;
  }
  .input-datepicker-container{
    display: grid;
    grid-template-columns: 50% 50%;
  }
  .simple-datepicker-container{
    display: block;
    margin: 0 auto;
    width: 100%;
  }
  .datepicker-container{
    display: block;
    position: relative;
    width: inherit;
  }
  .input-datepicker-wrapper{
    display: flex;
    margin: 0 auto;
    width: 100%;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: space-around;
    align-items: center;
    margin-top: 1em;
    margin-bottom: 2em;
  }
.calendar-container{
    position: relative;
    z-index: 5;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    width: 100%;
    display: grid;
    grid-template-columns: 45% 45%;
    -moz-column-gap: 5%;
         column-gap: 5%;

}
.simplecalendar-container{
    display: flex;
    flex-direction: column;
}
.calendar-wrapper{
    display: block;   
    width: 100%;
    position: relative;
    margin: 0 auto;
}


.year-container{
    position: relative;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: center;
    justify-content: center;
    align-items: center;
    font-family: 'Montserrat';
    width: 100%;
}
.sliding-year-month-wrapper{
    transform: translatex(-100%);
}
.year-month-wrapper{
    color: var(--calendarLightColor);
}
.year-wrapper{
    color: var(--calendarDarkColor);
}
.calendar-form-container{
    width: inherit;
    height: inherit;
}
.year-month-container{
    display: flex;
    flex-direction: row;
    width: 100%;
    position: relative;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: space-around;
    font-weight: 600;
    font-family: 'Montserrat';
    text-transform: capitalize;
    font-size: 1.3em;
}
.year-row{
    text-decoration: none;
    list-style: none;
    
}

.years-wrapper{
    display: flex;
    position: relative;
    margin: 0 auto;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    align-content: center;
    flex-wrap: nowrap;
}
.year-area{
    display: flex;
    position: relative;
    width: 100%;
    height: 100%;
    margin: 0 auto;
    text-align: center;
    background: rgba(238, 130, 238, 0.406);
    margin-top: 1em;
    border: none;
    border-radius: 20px;
}
.no-year-area{
    text-decoration: none;
    list-style: none;
    opacity: 0;
}
.month-th-group{
    display: grid;
    grid-template-columns: repeat(7, auto);
    grid-template-rows: none;
    width: 100%;
    justify-content: space-around;
}
.month-th-element{
    font-weight: 500;
    color: var(--calendarDarkColor);
}
.month-container{
    display: flex;
    position: relative;
    width: 100%;
    height: -moz-fit-content;
    height: -webkit-fit-content;
    height: fit-content;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: center;
}
.month-g{
    display: flex;
    flex-direction: column;
    position: relative;
    margin: 0 auto;
    background-color: var(--appWhiteColor);
    width: 100%;
}
.month-group{
    display: flex;
    flex-direction: column;
    position: relative;
    margin: 0 auto;
    margin-left: 2em;
    margin-right: 2em;
    background-color: var(--appWhiteColor);
}
.gonext{
    background-color: transparent;
    font-size: 2em;
    outline: none;
    border: none;
    color: var(--calendarLightColor);
    cursor: pointer;
}
.goback{
    background-color: transparent;
    font-size: 2em;
    outline: none;
    border: none;
    color: var(--calendarLightColor);
    cursor: pointer;
}
.ref-div{
    width: 50%;
    height: 300px;
    position: absolute;
    background-color: rgba(238, 130, 238, 0.406);
}
.hidden-month-group{
    display: flex;
    flex-direction: column;
    position: relative;
    margin: 0 auto;
    margin-left: 2em;
    margin-right: 2em;
    opacity: 0.5;
}
.box-button{
    padding: 0.5em;
    line-height: 2em;
    background: var(--appWhiteColor);
    border: none;
    font-weight: 500;
    color: var(--calendarMainColor);
    cursor: pointer;
    font-weight: 600;
    font-size: 0.8em;
}
.box-button-highlighted{
    -webkit-filter: brightness(1.5);
            filter: brightness(1.5);
    background-color: rgba(8, 131, 131, 0.682);
    color: white;
}
.box-button:hover{
    -webkit-filter: brightness(1.5);
            filter: brightness(1.5);
    background-color: rgba(8, 131, 131, 0.682);
    border-radius: 30px 30px 30px 30px;
    color: white;
    font-weight: 600;
}
.box-button:disabled{
    -webkit-filter: brightness(1);
            filter: brightness(1);
    background-color: var(--appWhiteColor);
    color: lightgrey;
    font-weight:100;
    cursor: default;
}
.box-button:disabled:hover{
    background-color: var(--appWhiteColor);
    color: grey;
    font-weight: 100;
    color: lightgrey;
    cursor: default;
}
.box-button-clicked{
    padding: 0.5em;
    line-height: 2em;
    -webkit-filter: brightness(1.5);
            filter: brightness(1.5);
    border-radius: 30% 10% 10% 30%;
    border: none;
    font-weight: 600;
    color: white;   
    background-color: var(--calendarMainColor);
    cursor: pointer;
}
.box-button-twice{
    padding: 0.5em;
    line-height: 2em;
    -webkit-filter: brightness(1.5);
            filter: brightness(1.5);
    border-radius: 30px 30px 30px 30px;
    border: none;
    font-weight: 600;
    color: white;    
    background-color: var(--calendarMainColor);
    cursor: pointer;
}


.box-button-clicked-end{
    padding: 0.5em;
    line-height: 2em;
    -webkit-filter: brightness(1.5);
            filter: brightness(1.5);
    border-radius: 10% 30% 30% 10%;
    border: none;
    font-weight: 600;
    color: var(--appDarkGold);
    background-color: var(--calendarMainColor);
    cursor: pointer;
}
.box-button-over{
    padding: 0.5em;
    line-height: 2em;
    background-color: rgba(5, 255, 255, 0.394);
    border: none;
}
.box-button-over:disabled{
    padding: 0.5em;
    line-height: 2em;
    background-color: rgba(5, 255, 255, 0.394);
    border: none;
}
.box-button-clicked:disabled{
    -webkit-filter: brightness(1.5);
            filter: brightness(1.5);
    background-color: var(--appWhiteColor);
    color: grey;
}
.box-button-clicked:active{
    -webkit-filter: brightness(1.5);
            filter: brightness(1.5);
    border-radius: 30% 10% 10% 30%;
    border: none;
    font-weight: 600;
    color: white;   
    background-color: var(--calendarMainColor);
}
.box-button-clicked:hover{
    -webkit-filter: brightness(1.5);
            filter: brightness(1.5);
    border-radius: 30% 10% 10% 30%;
    border: none;
    font-weight: 600;
    color: white;   
    background-color: var(--calendarMainColor);
}
.box-button-wrapper{
    display: grid;
    grid-template-columns: repeat(7, auto);
    grid-template-rows: repeat(6, auto);
    width: 100%;
  
}

.box-button-container{
    width: 100%;
    border: 1px solid var(--calendarMainColor);   
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}
.year-month-buttons-container{
    display: flex;
    position: relative;
    flex-direction: row;
}